/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { LastLocationProvider } from "react-router-last-location";
import { Routes } from "./app/router/Routes";
import { LayoutSplashScreen, ThemeProvider } from "./_metronic";
import { registerPushNotifications, registerServiceWorker } from './app/utils/PushUtils';

export default function App({ store, persistor, basename }) {
	useEffect(() => { 
		async function setUpServiceWorker() {
			try {
				await registerServiceWorker();
			} catch (e) {
				console.log(e);
			}
		}

		setUpServiceWorker();

		function monitorNotificationPermission() {
			console.log('Verificando status de permissão de notificações.')
			if (Notification.permission === 'denied') {
				showNotificationPermissionDialog();
			}
		}
		
		setInterval(monitorNotificationPermission, 1000 * 60 * 60);
		
		function showNotificationPermissionDialog() {
			if (window.confirm('As notificações foram desativadas. Deseja reativá-las?')) {
				requestNotificationPermission();
			}
		}
		
		function requestNotificationPermission() {
			Notification.requestPermission().then(permission => {
				if (permission === 'granted') {
					console.log('Permissão renovada!.');
					registerPushNotifications();
				} else {
					console.log('Permissão ainda não foi concedida.');
				}
			});
		}
	}, []);

	return (
		/* Provide Redux store */
		<Provider store={store}>
			{/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
			<PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
				{/* Add high level `Suspense` in case if was not handled inside the React tree. */}
				<React.Suspense fallback={<LayoutSplashScreen />}>
					{/* Override `basename` (e.g: `homepage` in `package.json`) */}
					<BrowserRouter basename={basename}>
						{/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
						<LastLocationProvider>
							{/* Provide Metronic theme overrides. */}
							<ThemeProvider>
								{/* Provide `react-intl` context synchronized with Redux state.  */}
								<Routes />
							</ThemeProvider>
						</LastLocationProvider>
					</BrowserRouter>
				</React.Suspense>
			</PersistGate>
		</Provider>
	);
}
